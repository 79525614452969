.subscribe {
  margin: 0 auto;
  padding-bottom: 140px;
  padding-top: 0px;
  background: url("/image/sparks.webp") no-repeat center 25%;
  width: 100%;
}

.subscribe .container1 {
/*  max-width: 1280px;
  margin: 0 auto;
  padding: 16px; */
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
  
}

.subscribe .container2 {
/*  max-width: 1280px;
  margin: 0 auto;
  padding: 16px; */
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
  
}

.subscribe .wrapper {
  background-color: #1A1B22;
  border-radius: 16px;
  padding: 59px 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.subscribe .title {
  font-family: 'Coalition', serif;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #ffffff;
  margin-bottom: 8px;
}

.subscribe .titlec {
  text-align: center;
}

.subscribe .text {
  font-family: 'Industry', serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #ffffff;
  margin-bottom: 28px;
}

.subscribe .textc {
  margin-bottom: 18px;
  text-align: center;
}

.subscribe .textg {
  color: rgba(215, 252, 81, 1);
}

.subscribe_linkItem {
  text-decoration: none;
  color: rgba(215, 252, 81, 1);
  transition: color .3s ease-in;
}

.active {
  text-decoration: underline;
  color: #ffffff;
}

.subscribe_linkItem:hover {
  color: #ffffff;
}

.subscribe .input {
  width: 100%;
  padding: 20px 140px 20px 16px;
  outline: none;
  font-family: 'Industry', serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #ffffff;
  opacity: .6;
  background: transparent;
  border: 1px solid #FFFFFF;
}

.subscribe .button {
  font-family: 'Industry', serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #1D1E29;
  background-color: #D7FC51;
  position: absolute;
  right: 0;
  height: 100%;
  padding: 24px;
}

.subscribe .error {
  border: 1px solid red;
}

.subscribe .errorText {
  font-family: 'Industry', serif;
  font-size: 1rem;
  color: red;
  margin-top: 4px;
}

.subscribe .image {
  height: 1px;
  justify-self: center;
}

.success {
  font-family: 'Industry', serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #1D1E29;
  background-color: #73C371;
  text-align: center;
  padding: 20px 0;
}

@media only screen and (min-width: 2000px)  {
  .subscribe .container1 {
    max-width: 80%;
  }
  .subscribe .container2 {
    max-width: 80%;
  }
  
}

@media only screen and (max-width: 768px)  {
  .subscribe {
    padding-bottom: 80px;
    padding-top: 20px;
	margin-top: 20px;
	margin-bottom:20px;
	font-zize: 10px;	
  }

  .subscribe .container1 {
    max-width: 100%;
	margin-left:0px;
	margin-right:0px;
	padding-left:10px;
	padding-right:10px;
	width: 100%;
	padding-bottom: 580px;
	margin-bottom:100px;
	font-zize: 10px;
  }

  .subscribe .container2 {
	width: 100%;	  
	margin-left:0px;
	margin-right:0px;
    max-width: 100%;
	padding-bottom: 200px;
	font-zize: 10px;
	padding-left:10px;
	padding-right:10px;
	
  }


  .subscribe .wrapper {
    padding: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: 200px 1fr;
  }

  .subscribe .title {
    font-size: 1.625rem;
    line-height: 2rem;
  }

  .subscribe .input {
    width: 100%;
    padding: 8px 106px 8px 16px;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .subscribe .button {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 8px;
  }

  .subscribe .image img {
    max-height: 300px;
    transform: scale(1.1);
  }

  .success {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 8px 30px;
  }
  
  .image {
	display: none;
  }
  
}
